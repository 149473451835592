




















































































































































































import { Component, Vue } from "vue-property-decorator";
// import {mapState, mapActions, mapGetters} from "vuex";
import filtersMixin from "@/mixins/filters";
// import vSelect from "vue-select";
import BigNumber from "bignumber.js";
import { BlockTransactionString } from "web3-eth";

import { MAX_ALLOWANCE_AMOUNT } from "@/constants";
import { Currency, GasConfig, GasPriceInfo, PoolInfo } from "@/types";
import state from "@/contract";
import { selectedNetwork } from "@/network";
import { fetchAPY, initToken } from "@/init";
import { timeout } from "@/helpers";
import { mapGetters } from "vuex";

import Popup, { TRANSACTION_STATUS } from "@/components/UI/Popup.vue";
import Alert from "@/components/UI/Alert.vue";
import PoolIcon from "@/components/UI/PoolIcon.vue";

@Component({
    name: "StakePool",
    mixins: [filtersMixin],
    data: () => ({
        selectedNetwork,
    }),
    components: {
        Alert,
        PoolIcon,
    },
    computed: {
        ...mapGetters([
            'getGasPriceOptions'
        ]),
    }
})
export default class Stake extends Vue {
    private WEI = new BigNumber(10).pow(-18);
    private gasPrice!: BigNumber;
    private poolIndex = this.$route.params.poolIndex;

    // TODO: hack! only render thi methods.allowance(spender)s component after state is ready
    private isReady = false;
    private approvedStake = false;
    private approvedPending = false;
    private stakePending = false;
    private transactionError = false;

    private approved?: BigNumber = new BigNumber(0);
    private staked: BigNumber = new BigNumber(0);
    private pendingRewardsAmount: BigNumber = new BigNumber(0.00);
    private harvestAmount: BigNumber = new BigNumber(0);

    private myBalance?: BigNumber;
    private sigBalance: any = 0.00;

    private approveInput = "0" // TODO: connect to input
    private stakeInput = "" // TODO: connect to input
    private unstakeInput = "" // TODO: connect to input
    private withdrawInput = "0" // TODO: connect to input
    
    private poolInfo: PoolInfo | null = null;
    private lpToken?: Currency;
    // private gasConfig?: GasConfig; // TODO: fetch from component

    private showAlert = false;
    private showUnstakeInp = false;
    private alertText = '';
    private alertTitle = '';

    getGasPriceOptions!: GasPriceInfo[];
    private gasOptionsSelect = { label: '', value: new BigNumber(0) };
    private pendingRewardsSpeed = new BigNumber(0); // per second

    private lastBlock: BlockTransactionString | null = null;
    private APY: number | null = null

    get tokenName () {
        if (this.lpToken) {
            return this.lpToken?.name;
        }

        if (selectedNetwork.chain === 'ETH') {
            if (Number(this.poolIndex) === 0) return "xSigma DEX DAI/USDC/USDT"
            if (Number(this.poolIndex) === 1) return "Uniswap DEX SIG/ETH LP"
            // if (Number(this.poolIndex) === 2) return "Mock LP"
            if (Number(this.poolIndex) === 3) return "SIG"
        } else {
            if (Number(this.poolIndex) === 0) return "xSigma DEX BUSD/USDC/USDT"
            if (Number(this.poolIndex) === 1) return "Cake SIG/BNB LP"
            // if (Number(this.poolIndex) === 2) return "Mock LP"
            if (Number(this.poolIndex) === 3) return "SIG"
        }

        return ''
    }

    get tokenShortName () {
        if (Number(this.poolIndex) === 0) return "SIG-LP"
        if (Number(this.poolIndex) === 1) return (selectedNetwork.chain === 'ETH') ? "UNI-V2" : "CAKE-LP"
        // if (Number(this.poolIndex) === 2) return "Mock LP"
        if (Number(this.poolIndex) === 3) return "SIG"
        return ''
    }

    get poolId () {
        if (Number(this.poolIndex) === 0) return 1
        if (Number(this.poolIndex) === 1) return 2
        // if (Number(this.poolIndex) === 2) return 0
        if (Number(this.poolIndex) === 3) return 3
        return ''
    }

    get ts () {
        return state.transactionPending
    }

    get gasConfig(): GasConfig | null {
        const gasOptions = this.getGasPriceOptions.slice(-1)[0];

        console.log('gasOptions', gasOptions)

        if (!gasOptions.value) { return null }

        return  {
            gasPrice: new BigNumber(gasOptions.value).times(1e9).integerValue(),
            gasLimit: 300_000,
        }
    }

    currentPendingRewardsAmount (): BigNumber {
        if (!this.lastBlock) { return this.pendingRewardsAmount }

        const passed = (Date.now() / 1000 - Number(this.lastBlock.timestamp)) // seconds

        return this.pendingRewardsSpeed.times(passed).plus(this.pendingRewardsAmount)
    }

    async created() {
        this.APY = null

        await this.updateCall()

        // TODO: update with ws events
        setInterval(() => this.updateCall(), 20000);

        fetchAPY(Number(this.poolId)).then((val) => this.APY = Number(val) || null)

        setInterval(() => {
            this.$forceUpdate();
        }, 100);
    }

    async updateCall () {
        while (!state.web3 || !state.isReady) {
            // DON'T RENDER AT ALL
            console.log("waiting 1 sec")
            await timeout(1000);
        }

        // TODO: THIS FAILS IF STATE IS NOT READY, don't create this component before init
        const poolInfo = await state.rewardsContract.methods.poolInfo(this.poolIndex).call()

        console.log('poolInfo:', poolInfo);
        this.poolInfo = poolInfo;

        const lpToken = await initToken(state.web3, poolInfo.lpToken);

        console.log('lpToken', lpToken);

        this.lpToken = lpToken;

        this.WEI = new BigNumber(1).div(lpToken.precision);

        try {
            await this.fetchBalances();
        } catch (err) {
            console.log('error fetching balances', err)
        }


        this.isReady = true;
    }

    openUnstakeInp() {
        this.showUnstakeInp = !this.showUnstakeInp
    }

    approveStake () {
        this.submitApproveInfinite()
    }

    maxStake () {
        if (!this.myBalance) { throw new Error("Balance Not Loaded yet") }
        if (this.stakePending) return;

        this.stakeInput = this.myBalance.minus(this.WEI).toFixed()
    }

    maxUnStake () {
        if (!this.myBalance) { throw new Error("Balance Not Loaded yet") }
        if (this.stakePending) return;

        this.unstakeInput = this.staked.toFixed();
    }

    harvest() {
        this.stake()
    }

    async fetchBalances() {

        if (!state.isReady) { throw new Error("State Not Loaded yet") }
        if (!this.lpToken) {
            console.error('LP Token not loaded yet')
            return
        }

        this.isReady = false;

        const myBalance = await this.lpToken.contract.methods.balanceOf(state.defaultAccount).call();

        this.myBalance = new BigNumber(myBalance).div(this.lpToken.precision);

        const approved = await this.lpToken.contract.methods.allowance(state.defaultAccount, state.rewardsContractAddress).call();
        this.approved = new BigNumber(approved).div(this.lpToken.precision);

        this.approvedStake = approved!=0;

        const { amount } = await state.rewardsContract.methods.userInfo(this.poolIndex, state.defaultAccount).call();
        this.staked = new BigNumber(amount).div(this.lpToken.precision);

        // SIG token
        const blockNumber = await state.web3.eth.getBlockNumber()
        const block = await state.web3.eth.getBlock(blockNumber)

        const delay = selectedNetwork.chain === 'ETH' ? 4 : 60 * 16; // ETH, BSC

        const pendingRewards = await state.rewardsContract.methods.pendingSushi(this.poolIndex, state.defaultAccount).call();

        const pendingRewardsNextBlock = await state.rewardsContract.methods.pendingSushi2(this.poolIndex, state.defaultAccount, blockNumber + delay).call();

        const pendingRewardsSpeedPerBlock = new BigNumber(pendingRewardsNextBlock).minus(pendingRewards).div(delay);

        // const blocksPerSecond = 1 / 15; // 1 block each 15 seconds for ETH
        // const blocksPerSecond = 1 / 3; // 1 block each 3 seconds for BSC
        const blocksPerSecond = selectedNetwork.chain === 'ETH' ? (1 / 15) : (1 / 3);

        this.pendingRewardsAmount = new BigNumber(pendingRewards).div(state.sigToken.precision);
        this.pendingRewardsSpeed = pendingRewardsSpeedPerBlock.times(blocksPerSecond).div(state.sigToken.precision);

        if (selectedNetwork.chain === 'ETH') {
            // HACK: show real values, smart contract has a bug.
            this.pendingRewardsAmount = this.pendingRewardsAmount.times(100).div(60);
            this.pendingRewardsSpeed = this.pendingRewardsSpeed.times(100).div(60);
        }

        /* eslint-enable @typescript-eslint/camelcase */
        this.lastBlock = block;

        console.log('speed', this.pendingRewardsSpeed);

        const sigBalance = await state.sigToken.contract.methods.balanceOf(state.defaultAccount).call();
        this.sigBalance = new BigNumber(sigBalance).div(state.sigToken.precision);

        this.isReady = true;
    }

    async submitApprove(event: Event) {
        event.preventDefault();
        if (!this.lpToken) { throw new Error("LP token not init yet") }

        const amount = new BigNumber(this.approveInput).times(this.lpToken.precision);
        const isInfinite = true; // TODO: button

        await this.approve(amount, isInfinite);
    }

    async submitApproveInfinite() {
        const amount = new BigNumber(0);
        await this.approve(amount, true);
    }

    async submitStake(event: Event) {
        event.preventDefault();

        if (!this.lpToken) { throw new Error("LP token not init yet") }

        const amount = new BigNumber(this.stakeInput).times(this.lpToken.precision);

        await this.stake(amount);
    }

    async submitWithdraw(event: Event) {
        event.preventDefault();

        if (!this.lpToken) { throw new Error("LP token not init yet") }
        let amount: any;

        if (this.unstakeInput !== '') {
            amount = new BigNumber(this.unstakeInput).times(this.lpToken.precision)
        } else {
            amount = new BigNumber(this.staked).times(this.lpToken.precision);
        }

        await this.unstake(amount);
    }

    async approve (amount: BigNumber, isInfinite = false) {
        if (!state.isReady) { throw new Error("State Not Loaded yet") }
        if (!this.lpToken) { throw new Error("LP token not init yet") }

        if (isInfinite) {
            amount = MAX_ALLOWANCE_AMOUNT;
        }

        console.log('approve', state.rewardsContractAddress, amount.toFixed(0));

        const method = this.lpToken.contract.methods.approve(state.rewardsContractAddress, amount.toFixed(0));
        let result;

        try {
            this.alertTitle = TRANSACTION_STATUS.waitingForConfirmationTitle;
            this.alertText = TRANSACTION_STATUS.waitingForConfirmationText;
            this.showAlert = true;

            console.log('approve', state.rewardsContractAddress, amount.toFixed(0));

            result = method.send({
                from: state.defaultAccount,
                ...this.gasConfig,
                gasLimit: 100_000, // for APPROVE only
            })

            result.on('transactionHash', (hash: string) => {
                console.log('tx hash', hash)
                this.approvedPending = true;
                state.transactionPending = true
                this.stakePending = true
                setTimeout(() => {
                    this.showAlert = false
                }, 1400)
                this.alertTitle = '';
                this.alertText = TRANSACTION_STATUS.created;
                this.showAlert = true;
            })
            result.on('receipt', (receipt: any) => {
                console.log('tx hash', receipt.transactionHash)

                this.$notify(Popup.tx.success(TRANSACTION_STATUS.approved, receipt.transactionHash))

                this.$store.dispatch('GET_BALANCE')
                this.approvedStake = true;
                this.approvedPending = false;
                this.stakePending = false
                state.transactionPending = false;
                this.updateCall()
            })
            result.on('error', (error: any) => {
                console.error('error staking', error)
                this.alertTitle = ''
                this.alertText = TRANSACTION_STATUS.stakeReverted
                this.showAlert = true;
                this.transactionError = true
                state.transactionPending = false
                setTimeout(() => {
                    this.showAlert = false
                    this.transactionError = false
                }, 2000)
            })
        } catch (e) {
            console.error('error approve', e)
            this.alertTitle = '';
            this.alertText = TRANSACTION_STATUS.stakeReverted;
            this.showAlert = true;
        }


    }

    async stake (amount?: BigNumber) {
        if (!state.isReady) { throw new Error("State Not Loaded yet") }
        if (this.stakePending) return;

        const amountStr = (amount && !amount.isNaN()) ? amount.toFixed(0) : '0';
        const method = state.rewardsContract.methods.deposit(this.poolIndex, amountStr);

        console.log('deposit', this.poolIndex, amountStr)

        let result;

        try {
            this.alertTitle = TRANSACTION_STATUS.waitingForConfirmationTitle;
            this.alertText = TRANSACTION_STATUS.waitingForConfirmationText;
            this.showAlert = true;

            result = method.send({
                from: state.defaultAccount,
                ...this.gasConfig,
                gasLimit: 300_000,
            })
            result.on('transactionHash', (hash: string) => {
                console.log('tx hash', hash)
                this.alertTitle = '';
                this.alertText = TRANSACTION_STATUS.created;
                this.showAlert = true;
                this.stakePending = true;
                setTimeout(() => {
                    this.showAlert = false
                }, 1400)
                state.transactionPending = true;
            })
            result.on('receipt', (receipt: any) => {
                console.log('tx hash', receipt.transactionHash)

                this.$notify(Popup.tx.success(TRANSACTION_STATUS.staked, receipt.transactionHash))

                this.stakePending = false;
                state.transactionPending = false;
                this.stakeInput = ''
                this.$store.dispatch('GET_BALANCE')
                this.updateCall()
            })
            result.on('confirmation', (hash: string) => {
                console.log('tx hash', hash)
                this.updateCall()
            })
            result.on('error', (error: any) => {
                console.error('error staking', error)
                this.alertTitle = ''
                this.alertText = TRANSACTION_STATUS.stakeReverted
                this.showAlert = true;
                state.transactionPending = false
                this.stakePending = false
                this.transactionError = true
                setTimeout(() => {
                    this.showAlert = false
                    this.transactionError = false
                }, 2000)
            })
        } catch (e) {
            console.error('staking error', e)
            this.alertTitle = '';
            this.alertText = TRANSACTION_STATUS.stakeReverted;
            this.showAlert = true;
        }
    }

    async unstake (amount: BigNumber) {
        if (!state.isReady) { throw new Error("State Not Loaded yet") }
        if (this.stakePending) return;

        const amountStr = (amount && !amount.isNaN()) ? amount.toFixed(0) : '0';
        const method = state.rewardsContract.methods.withdraw(this.poolIndex, amountStr);
        let result;

        console.log('withdraw', this.poolIndex, amountStr)

        try {
            this.alertTitle = TRANSACTION_STATUS.waitingForConfirmationTitle;
            this.alertText = TRANSACTION_STATUS.waitingForConfirmationText;
            this.showAlert = true;

            result = method.send({
                from: state.defaultAccount,
                ...this.gasConfig,
                gasLimit: 300_000,
            })
            result.on('transactionHash', (hash: string) => {
                console.log('tx hash', hash)
                this.alertTitle = '';
                this.alertText = TRANSACTION_STATUS.created;
                this.showAlert = true;
                setTimeout(() => {
                    this.showAlert = false
                }, 1400)
                this.stakePending = true;
                state.transactionPending = true;
            })
            result.on('receipt', (receipt: any) => {
                console.log('tx hash', receipt.transactionHash)

                this.$notify(Popup.tx.success(TRANSACTION_STATUS.unstake, receipt.transactionHash))

                this.stakePending = false;
                state.transactionPending = false;
                this.unstakeInput = ''
                this.showUnstakeInp = false
                this.updateCall()
                this.$store.dispatch('GET_BALANCE')
            })
            result.on('error', (error: any) => {
                this.alertTitle = ''
                this.alertText = error.message
                this.showAlert = true;
                this.transactionError = true
                state.transactionPending = false
                setTimeout(() => {
                    this.showAlert = false
                    this.transactionError = false
                }, 2000)
            })
        } catch (e) {
            this.alertTitle = '';
            this.alertText = TRANSACTION_STATUS.stakeReverted;
            this.showAlert = true;
        }
    }

}
